import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Read All User Details (GET)
export const fetchUserDetails = createAsyncThunk(
	'fetchUserDetails',
	async (args, { rejectWithValue }) => {
		const response = await fetch(`https://jsonplaceholder.typicode.com/users`, {
			method: 'GET',
			headers: {
				// Authorization: `Bearer ${args[0]}`,
				'Content-Type': 'application/json',
			},
		});

		try {
			const result = await response.json();

			return result;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const userDetail = createSlice({
	name: 'userDetail',
	initialState: {
		user: [],
		message: '',
		isLoading: false,
		error: '',
	},
	reducers: {
		// Other reducers for adding, removing, updating todos can go here
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUserDetails.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchUserDetails.fulfilled, (state, action) => {
				state.isLoading = false;
				state.user = action.payload;
				state.message = 'Successfully fetched user details';
			})
			.addCase(fetchUserDetails.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.payload;
				state.message = 'Error in fetching user details';
			});
	},
});

export default userDetail.reducer;
