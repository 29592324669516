'use client';

import { GlobalProvider } from './GlobalContextProvider';
import StoreProvider from './StoreProvider';
import { Inter } from 'next/font/google';
import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import theme from '../styles/theme';
import NextNProgress from 'nextjs-progressbar';
import 'bootstrap/scss/bootstrap.scss';
import style from './layout.module.css';
import Cookies from 'js-cookie';
import GoogleAnalytics from './GoogleAnalytics';
import { CustomNotFoundBoundary } from '@/components/CustomNotFoundBoundary';

const inter = Inter({ subsets: ['latin'] });

const metadata = {
	title: 'Kasha Pharma b2b',
	description: 'Generated by Kasha',
};
export const ThemeContext = React.createContext();

export default function RootLayout({ children }) {
	const [analyticsEvent, setAnalyticsEvent] = useState(null);

	useEffect(() => {
		const token = Cookies.get('token');
		if (!token) {
			localStorage.removeItem('pharma_customer');
			localStorage.removeItem('after_verification');
		}

		if (token) {
			const userData = JSON.parse(localStorage.getItem('pharma_customer'));
			if (userData) {
				setAnalyticsEvent({
					event: 'user_interaction',
					event_category: 'User',
					event_label: `Fullname: ${userData.fullname} tel: ${userData.phone_number}`,
				});
			} else {
				setAnalyticsEvent({
					event: 'user_interaction',
					event_category: 'User',
					event_label: 'User: None logged in user',
				});
			}
		}
	}, []);

	return (
		<StoreProvider>
			<html lang='en'>
				<head></head>
				<body className={style.globalFont} style={{ margin: 0 }}>
					<GlobalProvider>
						<ThemeProvider theme={theme}>
							<NextNProgress
								color='#29D'
								startPosition={0.3}
								stopDelayMs={0}
								height={3}
								showOnShallow={true}
							/>
							{analyticsEvent && (
								<GoogleAnalytics
									event={analyticsEvent.event}
									event_category={analyticsEvent.event_category}
									event_label={analyticsEvent.event_label}
								/>
							)}
							<CustomNotFoundBoundary
								notFound={<div>Page Not Found</div>}
								notFoundStyles={<style>{'/* custom styles */'}</style>}>
								{children}
							</CustomNotFoundBoundary>
							<div id='popup-modal' />
						</ThemeProvider>
					</GlobalProvider>
				</body>
			</html>
		</StoreProvider>
	);
}
