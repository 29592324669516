// Fonts:

let fontMain = `'Nunito Sans', sans-serif`;

let fontSecondary = 'Roboto Slab, serif';

/*${props => props.theme.colors.grey4}*/
// Colors:
let main = '#4a4a4a';
let grey = '#9b9b9b';
let grey2 = '#d8d8d8';
let grey3 = '#495057';
let grey4 = '#efefef';
let grey5 = '#EAEAEA';
let grey6 = '#f8f8f8';
let green = '#16bebb';
let green2 = '#25D366';
let green3 = '#26BDAB';
let black = '#000';
let red = '#f2111c';
let orange = '#dd4c39';
let pink = '#E2156A';
let strongPink = '#b92479';
let lightPink = '#fdd5de';
let lightGrey = '#bdbdbd';
let white = '#fff';
let coral = '#ff9280';
let brown = '#a53e28';
let tapestry = '#68c4ad';
let tapastryDark = '#1c8f82';
let blue = '#5b9fec';
let blue2 = '#295396';
let purple = '#6c4359';
let magenta = '#E2156A';
let color_white_hover = 'rgba(255, 255, 255, .5)';

/*${props => props.theme.spaces.normal}*/
const spaces = {
	little: '4px',
	mini: '8px',
	small: '16px',
	normal: '24px',
	medium1: '32px',
	medium2: '40px',
	medium3: '48px',
	large1: '56px',
	large2: '64px',
	large6: '75px',
	large3: '128px',
	large5: '144px',
	large4: '176px',
	large8: '210px',
	large9: '350px',
	large10: '380px',
	inputHeight: '42px',
	btnHeight: '42px',
	space110: '110px',
};

const heading = `
	font-family: ${fontMain};
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    color: ${main};
    padding: 0;
`;

const body = `
	font-family: ${fontMain};
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    color: ${main};
    padding: 0;
`;

const btnPrimaryStyle = `
    display: inline-block;
    font-weight: 400;
    color: ${white};
    text-align: center;
    vertical-align: middle;
    user-select: none;
    line-height: 44px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	padding: 0 ${spaces.mini};
    min-width: 44px;
    width: auto;
    height: 44px;
	cursor: pointer;
    background-color: ${magenta};
    border-radius: 0;
    border: none;

    &:hover {
        background-color: ${strongPink};
    }
`;

const theme = {
	colors: {
		main: main,
		grey: grey,
		grey2: grey2,
		grey3: grey3,
		grey4: grey4,
		grey5: grey5,
		grey6: grey6,
		green: green,
		green3: green3,
		black: black,
		red: red,
		pink: pink,
		strongPink: strongPink,
		lightPink: lightPink,
		lightGrey: lightGrey,
		white: white,
		coral: coral,
		brown: brown,
		tapestry: tapestry,
		tapastryDark: tapastryDark,
		blue: blue,
		blue2: blue2,
		orange: orange,
		purple: purple,
		magenta: magenta,
		color_white_hover: color_white_hover,
	},

	fontFamily: {
		main: fontMain,
		secondary: fontSecondary,
	},

	h1: `
		${heading}
		font-size: 26px;
	`,

	h2: `
		${heading}
		font-family: ${fontSecondary};
	    font-size: 24px;
	    margin-bottom: 1.875rem;
	`,

	h3: `
		${heading}
		font-size: 20px;
	`,

	h4: `
		${heading}
	    font-size: 16px;
	`,

	h5: `
		${heading}
		font-size: 14px;
	`,

	h6: `
		${heading}
		font-size: 12px;
	`,

	p: `
		${body}
		font-size: 14px;
	`,

	body_large: `
		${body}
		font-size: 20px;

		@media screen and (max-width: 991px) {
	        font-size: 14px;
	    }
	`,

	body_medium: `
		${body}
		font-size: 18px;

		@media screen and (max-width: 991px) {
	        font-size: 14px;
	    }
	`,

	body_normal: `
		${body}
		font-size: 16px;

		@media (max-width: 991px) {
		    font-size: 14px;
		}
	`,

	body_small: `
		${body}
		font-size: 14px;
	`,

	body_mini: `
		${body}
		font-size: 11px;
	`,
	spaces: { ...spaces },
	grid: {
		'grid-fullwidth': `
			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: 1fr;
			gap: 0px ${spaces.small};
			grid-template-areas: ".";
		`,
		'grid-4': `
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-template-rows: 1fr;
			grid-column-gap: ${spaces.small};
			grid-row-gap: ${spaces.small};
		`,

		'grid-3': `
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: 1fr;
			grid-column-gap: ${spaces.small};
			grid-row-gap: ${spaces.small};
		`,

		'grid-2': `
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: 1fr;
			grid-column-gap: ${spaces.small};
			grid-row-gap: ${spaces.small};
			grid-template-areas: "area-2 area-1";

			@media (max-width: 850px) {
				grid-template-columns: 1fr;
                grid-template-rows: 2fr;
                grid-template-areas: unset;
                grid-column-gap: unset;
                grid-row-gap: unset;
			}

			.area-2 {
				grid-area: area-2;
				position: relative;
			}
			.area-1 {
				grid-area: area-1;
				position: relative;
			}
		`,

		'grid-2-1': `
			display: grid;
			grid-template-columns: 2fr 1fr;
			gap: 0px ${spaces.small};

		`,

		'grid-1-2': `
			display: grid;
			grid-template-columns: 1fr 2fr;
			gap: 0px ${spaces.small};
		`,
	},
	buttons: {
		primary: `
			${btnPrimaryStyle}
		`,
		transparent_primary: `
            ${btnPrimaryStyle}
            background-color: ${white};
            border: 1px solid ${magenta};
            color: ${magenta};

            &:hover {
                background-color: ${magenta};
                color: ${white};
            }
        `,
		facebook: `
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
			background-color: ${blue2};
            padding: 0 ${spaces.mini};
            min-width: 44px;
            width: auto;
            height: 44px;
            cursor: pointer;
            color: ${white};
            text-decoration: none;

            svg {
                margin-right: ${spaces.mini};
                path {
                    fill: ${white}
                }
            }
		`,
		google: `
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            background-color: ${orange};
            padding: 0 ${spaces.mini};
            min-width: 44px;
            width: auto;
            height: 44px;
            cursor: pointer;
            color: ${white};
            text-decoration: none;

            svg {
                margin-right: ${spaces.mini};
                path {
                    fill: ${white}
                }
            }
		`,
		outline: `
			${btnPrimaryStyle}
			border: solid 1px ${main};
			background-color: transparent;
			text-decoration: none;

			&:hover {
			    background-color: transparent;
			    border-color: ${magenta};
			    color: ${magenta};

			    span {
			        color: ${magenta};
			    }
			}
		`,
		buy_btn: `
			display: inline-flex;
            padding: ${spaces.small} ${spaces.normal};
            align-item: center;
            justify-content: center;
            background-color: ${green2};
            border:1px solid ${white};
            color: ${white};
            font-weight: 700;
            text-decoration: none;

			&:hover {
			    background-color: transparent;
			    border-color: ${green2};
			    color: ${green2};

                svg path{
                    fill: ${green2};
                }
			}

            svg {
                margin-right: ${spaces.mini};
            }
		`,
	},

	text_input: `

	`,

	textarea: `
		resize: none;
	`,

	text_input_error: `
	`,
};

export default theme;
