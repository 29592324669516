// components/CustomNotFoundBoundary.js
'use client';

import React from 'react';
import { usePathname } from 'next/navigation';

class CustomNotFoundErrorBoundary extends React.Component {
	static getDerivedStateFromError(error) {
		if ((error == null ? void 0 : error.digest) === 'NEXT_NOT_FOUND') {
			return { notFoundTriggered: true };
		}
		// Re-throw if error is not for 404
		throw error;
	}

	static getDerivedStateFromProps(props, state) {
		if (props.pathname !== state.previousPathname && state.notFoundTriggered) {
			return { notFoundTriggered: false, previousPathname: props.pathname };
		}
		return {
			notFoundTriggered: state.notFoundTriggered,
			previousPathname: props.pathname,
		};
	}

	render() {
		if (this.state.notFoundTriggered) {
			return (
				<React.Fragment>
					{process.env.NODE_ENV === 'development' && (
						<meta name='next-error' content='not-found' />
					)}
					{this.props.notFoundStyles}
					{this.props.notFound}
				</React.Fragment>
			);
		}
		return this.props.children;
	}

	constructor(props) {
		super(props);
		this.state = {
			notFoundTriggered: !!props.asNotFound,
			previousPathname: props.pathname,
		};
	}
}

export function CustomNotFoundBoundary({
	notFound,
	notFoundStyles,
	asNotFound,
	children,
}) {
	const pathname = usePathname();
	return notFound ? (
		<CustomNotFoundErrorBoundary
			pathname={pathname}
			notFound={notFound}
			notFoundStyles={notFoundStyles}
			asNotFound={asNotFound}>
			{children}
		</CustomNotFoundErrorBoundary>
	) : (
		<React.Fragment>{children}</React.Fragment>
	);
}
