// localStorageMiddleware.js
const localStorageMiddleware = (store) => (next) => (action) => {
	const result = next(action);

	if (
		[
			'cartItems/addProductLocally',
			'cartItems/increaseQuantityLocally',
			'cartItems/decreaseQuantityLocally',
			'cartItems/manualUpdateQuantityLocally',
			'cartItems/deleteProductLocally',
			'cartItems/setCartData',
		].includes(action.type)
	) {
		const state = store.getState();

		localStorage.setItem(
			'cartItems',
			JSON.stringify(state.cartItems.cartItems)
		);
	}

	return result;
};

export default localStorageMiddleware;
