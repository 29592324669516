import React, { useEffect } from "react";
import Script from "next/script";
import Cookies from "js-cookie";
import PropTypes from 'prop-types';

const GoogleAnalytics = ({ event, event_category, event_label }) => {
  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      localStorage.removeItem("pharma_customer");
      localStorage.removeItem("after_verification");
    }

    // Ensure gtag function is defined before using it
    if (typeof window !== "undefined" && window.gtag) {
      // Call gtag function with event tracking data
      window.gtag("event", event, {
        event_category: event_category,
        event_label: event_label,
      });
    }
  }, [event, event_category, event_label]); // Ensure useEffect runs when these props change

  return (
    <>
      {/* Load Google Analytics script */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_TRACKING_CODE}`}
        onError={(e) => console.error("Failed to load Google Analytics script", e)}
      />

      {/* Initialize Google Analytics */}
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_TRACKING_CODE}');
        `}
      </Script>
    </>
  );
};

// PropTypes validation for props
GoogleAnalytics.propTypes = {
  event: PropTypes.string.isRequired,
  event_category: PropTypes.string.isRequired,
  event_label: PropTypes.string.isRequired,
};

export default GoogleAnalytics;
