'use client';

import { createContext, useState, useEffect, useContext } from 'react';
import { useRouter } from 'next/navigation';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
	const router = useRouter();

	const [isAuthenticated, setIsAuthenticated] = useState(false);

	useEffect(() => {
		// Check if the user is authenticated (using a token or local storage)
		const storedToken = localStorage.getItem('pharma_customer');
		if (storedToken) {
			setIsAuthenticated(true);
		} else {
			setIsAuthenticated(false);
		}
	}, []);

	const handleLogOut = () => {
		// Clear token on logout
		localStorage.removeItem('pharma_customer');
		setIsAuthenticated(false);
		router.push('/login');
	};

	return (
		<AuthContext.Provider
			value={{ isAuthenticated, setIsAuthenticated, handleLogOut }}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	return useContext(AuthContext);
};
