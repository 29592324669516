import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userDetailReducer from './features/userDetailSlice';
import cartItemsSlice from './features/cartItemsSlice';
import localStorageMiddleware from './localStorageMiddleware';

// Combining multiple reducers
const rootReducer = combineReducers({
	userDetail: userDetailReducer, // Token Slice
	cartItems: cartItemsSlice
});

// Configuring store with multiple reducers
export const makeStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(localStorageMiddleware),
    });
};
